import { DateTime, Duration } from "luxon";

export const TIME_FORMAT = "hh:mm";
export const TIME_FORMAT_HH_MM_SS = "hh:mm:ss";

class DurationUtils {
  secondsToHHMM(seconds) {
    if (seconds) {
      const duration = Duration.fromMillis(seconds * 1000);
      return duration.toFormat(TIME_FORMAT);
    } else {
      return "";
    }
  }

  millisToHHMM(millis) {
    if (millis) {
      const duration = Duration.fromMillis(millis);
      return duration.toFormat(TIME_FORMAT);
    } else {
      return "";
    }
  }

  secondsToHHMMSS(seconds) {
    if (seconds) {
      const duration = Duration.fromMillis(seconds * 1000);
      return duration.toFormat(TIME_FORMAT_HH_MM_SS);
    } else {
      return "";
    }
  }

  hhmmToSeconds(hhmm) {
    if (hhmm) {
      const dateTime = DateTime.fromFormat(hhmm, TIME_FORMAT);
      return dateTime.hour * 3600 + dateTime.minute * 60;
    } else {
      return null;
    }
  }
}

export default new DurationUtils();
